.home {
  display: flex;
  width: 100%;
  margin-top: 0px;
  
}

.home__image {
  max-height: 170px;
  width: 100%;
  margin-left: 12px;
  margin-right: 12px;
  object-fit: contain;
  margin-bottom: 15px;
}
.home__container {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.home__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  padding: 10px;
  width: 100%;
  max-height: 50%;
  background-color: white;
  z-index: 1;
  transition: transform 250ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.home__imageadvert {
  max-height: 300px;
  width: 100%;
  padding-right: 70px;
  object-fit: contain;
  margin-bottom: 5px;
}
.home__imageadvert1 {
  max-height: 400px;
  width: 100%;
 margin-left: 30px;
  height: auto;
  margin-bottom: 5px;
}
.home__imageadvert2 {
  max-height: 300px;
  width: 100%;
  padding-right: 220px;
  object-fit: contain;
  margin-bottom: 5px;
}
.home__imageadvert:hover {
  transform: scale(1.05);
}
.home__imageadvert1:hover {
  transform: scale(1.05);
}
.image-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.image-container img {
  display: block;
  width: 100%;
}

.header__option {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 5%;
  margin-top:5%;
  text-transform: capitalize;
  font-weight: bold;
  color: white;
}

.header__logo {
  width: 60%;
  max-width: 100px;
  object-fit: contain;
  margin: 10% 15%;
  margin-top: 2%;
}

.header__optionLineOne {
  font-size: 80%;
  margin-top: 20px;
  padding-left: 5px;
  padding-right: 5%;
  text-align:center;
  color: white;
}
.header__optionLineOne1 {
  font-size: 80%;
  margin-top: 20px;
  margin-right: 5%;
  margin-left: 5%;
  text-align:center;
  color: white;
}

.header__optionLineTwo {
  font-size: 95%;
  font-weight: 800;
  margin-top: 10px;
  margin-right: 5%;
  margin-left: 0%;
  color: white;
}

.header__optionBasket {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15%;
  width: 60%;
  margin-right: 15%;
  margin-top: 15%;
  color: white;
}

.navinator {
  display: flex;
  z-index: 1;
  list-style-type: none;
  font-size: 70%;
  font-weight: bolder;
  background-color: rgb(0 , 0, 108);
  margin-top: 0;
  width: 100%;
}

.anchor {
  display: block;
  color: white !important;
  text-align: center;
  font-size: 95%;
  padding: 5% 5%;
  text-decoration: none !important;
  cursor: pointer;
}
.anchor:hover {
  background-color: rgb(238, 166, 10) !important;
  padding-bottom: 10px;
  padding-right: 40px;
  color: black !important;
  font-weight: bold;
}

.active .anchor {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profile-image {
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
#profile {
  color: white !important;
  text-align: left;
  padding: 5px 6px;
  text-decoration: none !important;
  /* height: 52px; */
  overflow: auto;
}
#profile:hover {
  background-color: rgb(238, 166, 10) !important;
  cursor: pointer;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid white;
  margin-top: 10px;
}
.dropdown {
  opacity: 0;
  visibility: hidden;
  font-size: 15px;
  top: 100px;
  width: 152px;
  padding-bottom: 20px;
  padding-right: 20px;
  box-shadow: 10px 8px 16px 20px rgba(0, 0, 0, 0.2);
  /* display: inline-block; */
  background-color: rgb(238, 166, 10) ;
  position: absolute;
}
.drop-item:hover .dropdown {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}


.dropdown34 {
  opacity: 0;
  visibility: hidden;
  font-size: 25px;
  top: 100px;
  width: 180px;
  padding-bottom: 20px;
  padding-right: 220px;
  box-shadow: 10px 8px 16px 20px rgba(0, 0, 0, 0.2);
  /* display: inline-block; */
  background-color: rgb(6, 6, 6);
  position: absolute;
}
.drop-item34:hover .dropdown34 {
  opacity: 1;
  top: 90px;
  width: 80px;
  font-size: 15px;
  padding-right: 10px;
  visibility: visible;
  transition: all 0.3s;
}






.dropdown2 li {
  list-style-type: none;
}

.dropdown2 {
  opacity: 0;
  visibility: hidden;
  margin-left: 10px;
  top: 47px;
  width: 122px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* display: inline-block; */
  background-color: rgb(6, 6, 6);
  position: absolute;
}
.arrow-down2 {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 15px solid white;
  margin-top: -2px;
}
.drop-item2:hover .dropdown2 {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.dropdown2 li {
  list-style-type: none;
}

.badge {
  padding-left: 5px;
  padding-right: 9px;
  padding-bottom: 7px;
  padding-top: 5px;
}


#lblCartCount {
  font-size: 14px;
  font-weight: 900;
  color: white;
  position: absolute;
  border: solid rgb(249, 249, 252);
  border-radius: 60%;
  height: 14px;
  width: 8px;
  background:rgba(253, 5, 5, 0.952);
}




#lblCartCount2 {
  width: 9%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  
}

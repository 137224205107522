.addtobasketbuttonShow {
  background: #f0c14b;
  border: 1px solid;
  height: 35px;
  width: 135px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.profile-image1 {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
}


.home__container2000 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 350px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}


.mainly1 {
  grid-area: mainly;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1em;
  margin-left: 60px;
  margin-bottom: 60px;
  margin-top: 160px;
  margin-right: 60px;
}
.mainly1 li {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  border: solid 1px #dedede;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 1.8rem;
  display: grid;
  grid-template-columns: 21fr;
  grid-template-rows: auto minmax(40px, 1fr);
}
.allPaidProducts__info1 {
  background: #5ef340;
  border: 1px solid;
  margin-top: 10px;
  margin-left: 1px;
  height: 35px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  font-weight: bold;
}

.allPaidProducts__backinfo1 {
  background: #9b0202;
  border: 1px solid;
  margin-top: 10px;
  height: 35px;
  border-color: #c90505 #f7be04 #c70404;
  color: white;
  margin-left: 1px;
  font-weight: bold;
}

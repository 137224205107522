.thumbnail-gallery {
    padding-top: 160px;
    display: flex;
    flex-wrap: wrap;
  }

  .img-grid {
    padding-top: 160px;
    margin: 1px 1px;
    margin-left: 1px;
    margin-right: 1px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0%;
  }
  
  .thumbnail {
    margin: 10px;
    cursor: pointer;
  }
  
  .thumbnail img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  .Modal__overlay {
    padding-top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(220, 220, 20, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  
  .Modal__content {
    padding-top: 10px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    padding: 40px;
    max-width: 75%;
    z-index: 1;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  
  .Modal__content img {
    margin-top: 160px;
    width: 220px;
    height: auto;
    padding:20px;
    align-items:center;
    max-height: 450px;
   
  }
  
  .Modal__content p {
    margin-top: 20px;
  }
  
  .Modal__content button {
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
  }
  .btn-btn-danger{
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
  }

  .container_modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    margin: 5px;
    padding:10px;
    width: 75%;
    max-height: auto;
    background-color: white;
    z-index: 1;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .container_desc{
    display: flex;
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    padding-bottom: 10px;
    font-weight: 300;
    background-color: white;
    z-index: 1;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .container_descory{
    display: block;
    margin: auto;
    width: 90%;
    padding: 4px;
    font-weight: 500;
    text-align: center;
    background-color: white;
    z-index: 1;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .itemshopname {
    display: flex;
    max-width: 205%;
  }
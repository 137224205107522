

.container_134{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px;
  padding-top: 15px;
  margin-top: 0px;
  width: 95%;
  max-height: auto;
  min-width: 100px;
  background-color: white;
  z-index: 1;
  transition: transform 250ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}


.container_12{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 160px;
  padding-top: 1px;
  margin-top: 100px;
  font-weight: 400;
  width: 75%;
  max-height: auto;
  min-width: 100px;
  background-color: white;
  z-index: 1;
  transition: transform 250ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.container:hover {
  transform: scale(1.05);
}

.payment {
    background-color: white;
    padding-bottom: 65px;
    padding-left: 80px;
  }
  
  .header13__logo {
    max-height: 70px;
    width: 25%;
    margin-left: 12px;
    margin-right: 12px;
    object-fit: contain;
    margin-bottom: 15px;
  }
  .payment__container > h1 {
    text-align: center;
    padding: 10px;
    font-weight: 400;
    background-color: rgb(234, 237, 237);
    border-bottom: 1px solid lightgray;
  }
  .payment__container{
    width: 100%;
  
    
  }
  .payment__section2 {
    padding-left: 50px;
    padding-right: 60px;
  }
  
  .payment__container > h1 a {
    text-decoration: none;
  }
 
  .payment__title {
    flex: 0.2;
   
  }
  .payment__address,
  .payment__items,
  .payment__details {
    flex: 1.8;
    width: 100%;
   
  }
  .payment__details > form {
    max-width: 400px;
  }
  .payment__details > h3 {
    padding-bottom: 20px;
  }
  
  .payment__details > form > div > button {
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    font-weight: bolder;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
  }

  .allPaidProducts__updateproducts {
    background: #5ef340;
    border: 1px solid;
    margin-top: 10px;
    margin-left: 1px;
    height: 35px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    font-weight: bold;
  }

  .allPriceProducts__updateproducts {
    background: #00cf5e;
    border: 1px solid;
    margin-top: 10px;
    margin-left: 1px;
    height: 35px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    font-weight: bold;
  }
  
  .allPaidProducts__SoldOut {
    background: #9c0404;
    border: 1px solid;
    margin-top: 10px;
    margin-left: 1px;
    width: 120px;
    height: 35px;
    border-color: #a88734 #9c7e31 #846a29;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  
  .btn-btn-danger212 {
    background: #f7d203;
    border: 1px solid;
    margin-top: 10px;
    margin-left: 1px;
    width: 80px;
    height: 35px;
    border-color: #a88734 #9c7e31 #846a29;
    color: rgb(255, 255, 255);
    font-weight: bold;
  }
  
  .noticeBoard {

    background: #296af7;
    border: 5px solid;
    margin-top: auto;
    margin-left:auto;
    padding: 10px;
    height: auto;
    border-color: #a88734 #9c7e31 #c7961a;
    color: rgb(253, 251, 251);
    font-weight: bold;
  }

 
  
  .container_ee234 {
    flex-direction:row;
    align-items: center;
    margin-top: 1px;
    margin-left:10%;
    max-height: auto;
    max-width: 80%;
    background-color: white;
    z-index: 122;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .profile-image1 {
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
  }
  
.home__container2000 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
  .mainly11 {
  display: flex;
  flex-direction:row;
  align-items: center;
  margin-top: 5px;
  max-height: auto;
  max-width: 80%;
  background-color: white;
  z-index: 122;
  transition: transform 250ms;
  }
  .mainly11 li {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    border: solid 1px #dedede;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 1.8rem;
    display: grid;
    grid-template-columns: 21fr;
    grid-template-rows: auto minmax(40px, 1fr);
  }
  
  
  .allPaidProducts__backinfo1 {
    background: #9b0202;
    border: 1px solid;
    margin-top: 10px;
    height: 35px;
    border-color: #c90505 #f7be04 #c70404;
    color: white;
    margin-left: 1px;
    font-weight: bold;
  }
  .allPaidProducts__updateproducts {
    background: #017507;
    border: 1px solid;
    margin-top: 10px;
    height: 35px;
    width: 120px;
    border-color: #01380a #f76504 #010201;
    color: white;
    margin-left: 1px;
    font-weight: bold;
  }
  .boxed223 {
    margin-top: 20px;
    border: 2px solid green ;
    width: 935px;
    padding: 10px;
  }

  .boxed111223 {
    margin-top: 20px;
    border: 2px solid rgb(243, 3, 3) ;
    width: 80%;
    padding: 10px;
    background-color: rgb(248, 187, 113);
  }
  .boxedallaround {
    margin-top: 10px;
    border: 5px solid green ;
    
    padding: 10px;
  }


  .container_3334{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    padding-top: 0px;
    padding-bottom: 45px;
    width: 90%;
    padding-left: 45px;
    max-height: auto;
    min-width: 200px;
    background-color: white;
    z-index: 1;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .container_333{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 150px;
    padding-left: 10px;
    margin: 0px;
    padding-bottom: 45px;
    width: 100%;
    max-height: auto;
    min-width: 200px;
    background-color: white;
    z-index: 1;
    transition: transform 250ms;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  
  .item {
    display: inline-flex;
    font-weight: bold;
    align-items: center;
  }
  .item_info {
    font-weight: normal;
    width: 70%;
  }
  .item_rating {
    display: flex;
    justify-self: flex-start;
  }
  .item_size {
    display: flex;
    font-weight: bold;
  }
  .item_size1{
    display: flex;
    font-weight: bold;
  }
  
  .container_123:hover {
    transform: scale(1.05);
  }
  .container_123 > img:hover{
  transform: scale(2.80);
  display: block;
  margin-left: auto;
  margin-right: auto;
  
  }
  .container_123 > img {
    display: flex;
    width: 15%;
    height: 15%;
  }
  .item_colour {
    display: flex;
    font-weight: bold;
  }
  
  .item_price {
    align-items: center;
  }
  .container_123 > button {
    background: #f0c14b;
    border: 1px solid;
    height: 35px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
  }
  .btn-btn-danger{
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
  }
  
  .btn-btn-danger2{
   background: rgb(107, 181, 224);
    height: 220px;
    width: 250px; 
    border: 2px solid;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
  }
  th, td {
   
    text-align: center;
  }
  
  th {
  
    font-weight: bold;
  }
  
  td.active {
    background-color: #6F9;
  }
  
  td:hover {
    background-color: #eee;
    cursor: pointer;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
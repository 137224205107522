.header__optionfine {
    display: flex;
    flex-direction:column;
    text-transform: capitalize;
    text-align: center;
    font-weight: bold;
    color: rgb(252, 250, 250);
  }
  
  
  .header__optionBasketfine {
    display: flex;
    width: 30%;
    align-items: center;
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px;
    margin-left: 20px;
  }
 
  
  .navinatorfine {
    display: flex;
    z-index: 1000;
    list-style-type: none;
    margin: 0px;
    background-color: rgb(10, 144, 177);
    position: fixed;
    top: 0px;
    width: 100%;
    height: 140px;
  }
  
  
  .profile11-image {
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    border-radius:10%;
  }
  #profile11 {
    color: white !important;
    padding: 5px 5px;
    width: 100%;
    text-decoration: none !important;
    /* height: 52px; */
    overflow: auto;
  }
  #profile11:hover {
    background-color: rgb(230, 172, 16) !important;
   padding-bottom: 20px;
    cursor: pointer;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid white;
    margin-top: 10px;
  }
  .dropdownfine {
    opacity: 0;
    visibility: hidden;
    top: 100px;
    width: 252px;
    padding-bottom: 20px;
    box-shadow: 10px 8px 16px 20px rgba(0, 0, 0, 0.2);
    /* display: inline-block; */
    background-color: rgb(6, 6, 6);
    position: absolute;
  }

.itemfine{
  opacity: 0;
  visibility: hidden;
  top: 100px;
  width: 252px;
  padding-bottom: 20px;
  box-shadow: 10px 8px 16px 20px rgba(0, 0, 0, 0.2);
  /* display: inline-block; */
  background-color: rgb(6, 6, 6);
  position: absolute;

}


  .drop-itemfine:hover .dropdown {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  
  .dropdown2 li {
    list-style-type: none;
  }
  
  .dropdown2fine {
    opacity: 0;
    visibility: hidden;
    margin-left: 100px;
    top: 47px;
    width: 122px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    /* display: inline-block; */
    background-color: rgb(6, 6, 6);
    position: absolute;
  }
  .arrow-down2 {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 15px solid white;
    margin-top: -2px;
  }
  .drop-item2:hover .dropdown2 {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  
  .dropdown2fine li {
    list-style-type: none;
  }
  .header__optionfine2 {
    font-size: 15px;
    color: white;
  
  }
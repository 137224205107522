.boxed {
    margin-top: 20px;
    border: 2px solid green ;
    width: 935px;
    padding: 10px;
  }

  .boxed111 {
    margin-top: 20px;
    border: 2px solid rgb(243, 3, 3) ;
    width: 935px;
    padding: 10px;
    background-color: rgb(248, 187, 113);
  }
.image-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
   
  }
  
  .image-container img {
    display: block;
    width: 100%;
  height: 1250px;
    
  
  }
  

.container_123 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 85%;
  max-height: auto;
  min-width: 80%;
  background-color: white;
  z-index: 1;
  transition: transform 250ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}


.orders > h1 {
  margin: 30px;
}

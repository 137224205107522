.order {
  padding: 140px;
  margin: 120px 20px;
  border: 1px solid lightgray;
  background-color: white;
  width: 65%;
  position: relative;
}
.order__id {
  position: absolute;
  top: 40px;
  right: 20px;
}
.order__total {
  font-weight: 500;
  text-align: right;
}
.allPaidProducts2__image2 {
  object-fit: contain;
  width: 180px;
  height: 180px;
}
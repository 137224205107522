.addtobasketbuttonShow {
    background: #f0c14b;
    border: 1px solid;
    height: 35px;
    width: 135px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
  }
  
  .profile-image1 {
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
  }
  
  .mainly {
    grid-area: mainly;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 1em;
    margin-left: 60px;
    margin-bottom: 60px;
    margin-top: 60px;
    margin-right: 60px;
  }
  .mainly li {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    border: solid 1px #dedede;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 1.8rem;
    display: grid;
    grid-template-columns: 21fr;
    grid-template-rows: auto minmax(40px, 1fr);
  }
  
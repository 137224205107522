.allPaidProducts {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.allPaidProducts__info {
  padding-left: 20px;
}
.allPaidProducts__info > button {
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  margin-left: 1px;
  height: 30px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  font-weight: bold;
}

.allPaidProducts__backinfo > button {
  background: #9b0202;
  border: 1px solid;
  margin-top: 10px;
  height: 30px;
  border-color: #c90505 #f7be04 #c70404;
  color: white;
  margin-left: 19px;
  font-weight: bold;
}
.allPaidProducts__image {
  object-fit: contain;
  width: 280px;
  height: 180px;
}
.allPaidProducts__rating {
  display: flex;
}
.allPaidProducts__title {
  font-size: 17px;
  font-weight: 800;
}
.boxedNew {
  align-items: center;
  margin-top: 20px;
  border: 2px solid rgb(124, 2, 2) ;
  width: 100%;
  padding: 30px;
}
.allwPaidProducts2__prodinfo2{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
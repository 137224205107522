.home__row21 {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
  margin: 10px;
  padding: 10px;
  width: 30%;
  max-height: 50%;
  background-color: white;
  z-index: 3;
  transition: transform 250ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.home__imageadvert21 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 25px;
  margin-left: 25px;
  gap: 25px;
}

.img-grid_DVDs {
  padding-top: 160px;
  margin: 1px 1px;
  margin-left: 1px;
  margin-right: 1px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0%;
}

/* image grid styles */
.img-grid {
  padding-top: 160px;
  margin: 1px 1px;
  margin-left: 1px;
  margin-right: 1px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0%;
}
.img-wrap {
  padding-bottom: 75px;
  padding-top: 0px;
  display: block;
  max-width: 290px;
  max-height: 195px;
  width: auto;
  height: auto;
}

.img-wrap:hover {
  transform: scale(1.6);
  position: "absolute";
}

.item2 {
  padding-top: 40px;
  font-weight: bold;
  font-size: large;
  text-align: center;
}
.itemdisplay {
  font-weight: bold;
  font-size: large;
  text-align: center;
  margin-top: 15px;
  max-width: 80%;
  background-color: white;
}
.boxedallaroundsuper {
  border-top: 20px;
  margin-top: 120px;
  border: 5px solid black;

  padding: 10px;
}

.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  left: 20%;
  top: 10%;
}
.imgsoursepops {
  height: 40%;
  width: 40%;
}
.imgsoursepops:hover {
  transform: scale(2.8);
}

/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.item_size {
  margin-left: 1em;
  margin-right: 1em;
  font-size: 10px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1%;
}
.item_size1 {
  margin-left: 4em;
  margin-right: 4em;
  font-size: 15px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1%;
}
.checkbox_values1{
  height: 5.5rem;
  width: 2.5rem;
}

.checkbox_values {
  height: 3.5rem;
  width: 3.5rem;
}

.container_234 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;
  margin-left: 5%;
  margin-right: 5%;
  max-height: auto;
  max-width: 100%;
  background-color: white;
  z-index: 2;
  transition: transform 250ms;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  max-height: auto;
  max-width: 80%;
  background-color: white;
  z-index: 122;
  transition: transform 250ms;
}

.container_1234:hover {
  transform: scale(1.05);
}
.imgsourse:hover {
  transform: scale(2.8);
}

.imgsourse1 {
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
  height: 50px;
}

.imgsourse {
  padding-bottom: 100px;
  padding-top: 20px;
  height: 20%;
  width: 20%;
}
.addtobasketbutton {
  visibility: hidden;
}
.addtobasketlogo {
  background: #f0c14b;
  border: 1px solid;
  height: 35px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: center;
  font-size: 20px;
}

.item2 {
  padding-top: 40px;
  font-weight: bold;
  font-size: large;
  text-align: center;
}

.addtobasketbuttonShow {
  background: #f0c14b;
  border: 1px solid;
  height: 35px;
  padding-bottom: 5px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}
.hide {
  visibility: hidden;
}

body {
  display: block;
}
.span3:focus ~ .alert {
  display: none;
}
.span2:focus ~ .alert {
  background: #f0c14b;
  border: 1px solid;
  height: 35px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.popup-form {
  visibility: hidden;
}

.popup-form.show {
  visibility: visible;
}

.header5 {
  height: 180px;
  display: flex;
  align-items: center;
  background-color: #131921;
  top: 0;
  z-index: 100;
}

.ListItem5 {
  list-style: none;
  margin-bottom: 0.8em;
}

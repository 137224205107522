.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .checkoutProduct__info {
    padding-left: 20px;
  }
  .checkoutProduct__info > button {
    background: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    margin-left: 1px;
    height: 30px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    font-weight: bold;
  }

  .checkoutProduct__backinfo > button {
    background: #9b0202;
    border: 1px solid;
    margin-top: 10px;
    height: 30px;
    border-color: #c90505 #f7be04 #c70404;
    color: white;
    margin-left: 19px;
    font-weight: bold;
  }
  .checkoutProduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
  }
  .checkoutProduct__rating {
    display: flex;
  }
  .checkoutProduct__title {
    font-size: 17px;
    font-weight: 800;
  }
  